'use client';
import { Search } from '@mui/icons-material';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { SearchBox, StyledInput, StyledSearchButton } from './style';

export const SearchBar = () => {
  const [searchInput, setSearchInput] = useState('');
  const router = useRouter();
  return (
    <SearchBox
      onSubmit={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (searchInput.trim().length === 0) {
          setSearchInput('');
          return;
        }

        router.push(`/search?searchTerm=${searchInput}&page=1`);
      }}
    >
      <StyledInput
        type="text"
        placeholder="Search"
        sx={{ fontSize: '0.7rem', padding: 0.5 }}
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />
      <StyledSearchButton type="submit">
        <Search sx={{ color: 'black', '&:hover': { color: 'white' } }} />
      </StyledSearchButton>
    </SearchBox>
  );
};
