'use client';

import styled from '@mui/material/styles/styled';

export const StyledFooterCard = styled('footer')(() => ({
  backgroundColor: 'transparent',
  width: '100%',
  height: 'auto',
  maxHeight: 'auto',
  minHeight: '16rem',
  boxShadow: 'inherit',
  padding: '0.5rem',
  borderTop: '1px solid #CCC',
  display: 'flex',
  alignItems: 'start',
  marginTop: '1rem',
}));
