'use client';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Link from 'next/link';
import * as React from 'react';

export default function SideDrawer() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {links.map((link, index) => (
          <ListItem key={link.title} disablePadding>
            <Link href={link.url}>
              <ListItemButton>
                <ListItemText primary={link.title} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );

  return (
    <div>
      <MenuIcon
        sx={{
          display: { sm: 'block', md: 'none' },
          color: 'black',
          cursor: 'pointer',
        }}
        onClick={toggleDrawer(true)}
      />
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}

const links = [
  {
    url: '/',
    title: 'Home',
  },
  {
    url: '/sports',
    title: 'Sports',
  },
  {
    url: '/privacy-policy',
    title: 'Privacy Policy',
  },
  {
    url: '/terms-of-use',
    title: 'Terms of Use',
  },
];
